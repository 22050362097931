import type { ZudokuConfig } from "zudoku";


const config: ZudokuConfig = {
  
  topNavigation: [
    { id: "bnpl", label: "🏠︎ API Home" },
    { id: "documentation", label: "📖 Documentation" },
    

  ],
  
  metadata: {
    title: "Rupifi API Documentation",
    description: "Rupifi is a B2B digital finance platform that helps the financial growth of small and medium enterprises.",
    logo: "/images/logo-black.png",
    favicon: "/images/favicon.png",
    generator: "Jahangeer Wani",
    applicationName: "Rupifi API Documentation",
    keywords: ["Rupifi API Documentation", "Rupifi", "API", "Docs", "B2B", "BNPL", "Buy Now Pay Later", "Loan"],
    authors: ["Jahangeer Wani"],
    creator: "Jahangeer Wani",
    publisher: "Jahangeer Wani"
  },


  page: {
    logo: {
      src: {
        light: "/images/logo-black.png",
        dark: "/images/logo-white.png"
      },
      width: "99px"
    }
  },

  sidebar: {
    
    bnpl: [
      // Added additional docs
    {
        type: "link",
        label: "Support",
        href: "https://rupifi.com/contact"
    },
    { type: "doc", label: "🔑 APIs", id: "../bnpl"},
    ],
   
    documentation: [
     
      // Added additional docs
      { type: "category", label: "📔 Callbacks", items: ["Lead-Callbacks","Payment-Callbacks","Settlement-Callbacks","Repayment-Callbacks"] },
      { type: "doc", label: "Order-Flow-Diagram", id: "Order-Flow-Diagram"},
      { type: "doc", label: "In-app Integration", id: "In-app-Integration"},
      { type: "doc", label: "Handling UPI Intent in Android & iOS", id: "UPI-Intent-Android-iOS"},

      { type: "category", label: "📝 Schemas", items: ["/schemas/eligibility","/schemas/payment","/schemas/capture","/schemas/void","/schemas/refund","/schemas/account","/schemas/account-statement","/schemas/document","/schemas/amount","/schemas/metadata","/schemas/settlement","/schemas/settled-entity"]  },
      { type: "doc", label: "🔑 APIs", id: "../bnpl"},
      {
        type: "link",
        label: "Support",
        href: "https://rupifi.com/contact"
      },
    ],

  },


  redirects: [
    { from: "/", to: "/bnpl" },
  ],

  apis: [
    {
      type: "file",
      input: "./apis/bundled.json",
      navigationId: "bnpl",
      
    },
  ],

  

};



export default config;
